<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>所属门店：</p>
          <select @change="getData(1)" v-model="form.fenpeimendianId">
            <option value="">全部</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>员工级别：</p>
          <select v-model="form.jibieId" @change="getData(1)">
            <option value="">全部</option>
            <option :value="item.id" v-for="item in shareList" :key="item.id">{{item.shareName}}</option>
          </select>
        </li>
        <li>
          <p>员工工号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.userCode">
        </li>
        <li>
          <p>员工姓名：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.xingming">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td style="text-align:center">工号</td>
            <td>登录账号</td>
            <td>姓名</td>
            <td>手机号</td>
            <td>性别</td>
            <td>所属门店</td>
            <td>级别</td>
            <td>生日</td>
            <td>邮箱</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td style="text-align:center">{{item.userCode}}</td>
            <td>{{item.userName}}</td>
            <td>{{item.xingming}}</td>
            <td>{{item.phone}}</td>
            <td>{{item.xingbie}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{item.jibieName}}</td>
            <td>{{getShengri(item.shengri)}}</td>
            <td>{{item.dianziyouxiang}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)" v-pow:2702>管理</button>
                <button type="button" class="pur" @click="resetPassword(item.id,item.xingming)" v-pow:2702>重置密码</button>
                <button type="button" class="err" @click="delOneData(item.id,item.xingming)" v-pow:2703>删除</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="newProductAdd" v-pow:2701>新增员工</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'packageSettings',
  data() {
    return {
      infoShow: false,
      title:'',
      dataList:[],
      storeList:[],
      shareList:[],
      form:{
        fenpeimendianId:'',
        userCode:'',
        xingming:'',
        jibieId:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
     this.getData(1)
     this.getShare()
     this.getStore()
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    getShengri(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD")
      else return ''
    },
    delOneData(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/emp/del',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    resetPassword(id,name){
      this.$trueOrFalse({
        title: '重要提示',
        content: `确认重置 ${name} 的密码为默认密码 123456 ?`,
        torf: false,
        suc:()=>{
          axios.post('/emp/resetPass',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setInfoData(item){
      this.title=item.xingming
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    getShare(){
      axios.post('/share/list').then((res) => {
        if(res.code===5000){
          this.shareList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/emp/listPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
    newProductAdd(){
      this.title="新增员工"
      this.MmsData.new=true
      this.infoShow=true
    },
    showInfo(){
      this.infoShow=true
    }
  },
}
</script>
